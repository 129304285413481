import './styles.sass';

import AboutUs from './AboutUs';
import AcademyHIW from './AcademyHIW';
import CharlesMeta from '../common/meta/CharlesMeta';
import CoachDetails from './CoachDetails';
import { Container } from 'react-bootstrap';
import DefaultHeader from '../common/MiaLayout/DefaultHeader';
import Faq from './Faq';
import FormationDetails from './FormationDetails';
import LandingHero from './LandingHero';
import { Layout } from '../common';
import PressAcademy from './PressAcademy';
import React from 'react';
import Testimonials from './Testimonials';
import VaginismeEfficacity from './VaginismeEfficacity';
import EmailForm from './AcademyTeaserEmail/EmailForm';
import WhyUs from './WhyUs';
import { frontAppUrl } from 'utils/siteConfig';

const startAcademyOnboardingFor = (slug) => {
  window.location.href = `${frontAppUrl}/academy.html?slug=${slug}&refresh=true`;
};

const IndexPage = (props) => (
  <Layout
    showBars={false}
    showOnlyFooter
    showLinksInFooter={false}
    showSpecialtiesInFooter={false}
  >
    <CharlesMeta
      data={{
        title: `${props.data.meta.title}`,
        description: `${props.data.meta.description}`,
      }}
    />

    <DefaultHeader /* showPromo */ />

    <div className="academy-landing-page">
      <LandingHero data={props.data.hero} goToAcademy={() => startAcademyOnboardingFor(props.data.slug)}/>
      <WhyUs data={props.data.whyUs} />
      <AcademyHIW data={props.data.hiw} goToAcademy={() => startAcademyOnboardingFor(props.data.slug)}/>
      <FormationDetails data={props.data.efficacity} />
      <VaginismeEfficacity data={props.data.efficacity} goToAcademy={() => startAcademyOnboardingFor(props.data.slug)}/>
      <CoachDetails data={props.data.coach} />
      <Testimonials data={props.data.testimonials} from="LandingProgram" />
      <Faq data={props.data.faq} goToAcademy={() => startAcademyOnboardingFor(props.data.slug)} />
      <AboutUs />
      <Container className="lc-white press-band">
        <PressAcademy data={props.data.press} />
        <EmailForm />
      </Container>
    </div>
  </Layout>
);

export default IndexPage;
