import './styles.sass';

import Button from 'react-bootstrap/Button';
import Image from '../../common/utils/Image';
import React from 'react';
import Section from '../../common/Section';

const IndexPage = (props) => (
  <Section className="academy-section academy-section-hiw">
    <div className="academy-hiw-wrapper">
      <h2>Comment ça marche ?</h2>
      <div className="academy-hiw-step-wrapper">
        {props.data.items.map((item, i) => (
          <div className="academy-hiw-step">
            <div className="academy-img-card">
              <Image filename={item.image} />
            </div>
            <span className="academy-hiw-number">0{i + 1}</span>
            <span className="academy-hiw-step-title">
              {item.title}
            </span>
            <span className="academy-hiw-step-text">
              {item.text}
            </span>
          </div>
        ))}
      </div>
      <Button onClick={() => props.goToAcademy()} className="btn-orange academy-start-cta">
        Commencer
      </Button>
    </div>
  </Section>
);

export default IndexPage;
