export function isEmpty(value) {
  if (value == null || value === undefined) {
    return true;
  }
  if (value && value.length) return false;
  return true;
}

// https://stackoverflow.com/a/37826698/1991244
export function chunk(array, size) {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function first(array) {
  return (array && array.length) ? array[0] : undefined;
}
