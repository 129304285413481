import './styles.sass';

import { Col, Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Section from '../../common/Section';
import { StaticImage } from 'gatsby-plugin-image';
import TextSectionList from 'components/common/TextSectionList';
import cx from 'classnames';
import vaginismeFaq from '../../LandingVaginisme/data.json';

export default class Faq extends Component {
 
  render() {
    let faqContent = vaginismeFaq.faq.content;
    
    return (
      <Section className="academy-section academy-section-faq " id="faq">
        <Container className="academy-faq-container d-flex flex-column justify-content-center ">
          <h2>FAQ</h2>
          <div className="d-flex justify-content-center">
            <TextSectionList sections={faqContent} />
          </div>
          <div className="d-flex justify-content-center mt-3">
          <Button onClick={() => this.props.goToAcademy()} className="btn-orange academy-start-cta academy-cta-margin d-flex justify-content-center">
        Commencer
      </Button>
      </div>
        </Container>
      </Section>
    );
  }
}
