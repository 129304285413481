import './styles.sass';

import Carousel from 'react-bootstrap/Carousel';
import ChevronLeft from 'images/open-iconic/svg/chevron-left.svg';
import ChevronRight from 'images/open-iconic/svg/chevron-right.svg';
import Col from 'react-bootstrap/Col';
import Image from 'components/common/utils/Image';
import { Link } from 'gatsby';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { chunk } from '../../../utils/lodashExtracts';
import { publishedArticles } from './articles';

const AcademyCarouselCard = ({
  linkToPress = true,
  brandImage,
  media,
  location,
}) => (
  <div className="">
    {linkToPress ? (
      <Link to="/presse/">
        <Image
          filename={brandImage}
          alt={media}
          className="press-img img-fluid"
        />
      </Link>
    ) : (
      <a href={location} target="_blank" rel="noopener noreferrer nofollow">
        <Image
          filename={brandImage}
          alt={media}
          className="press-img img-fluid"
        />
      </a>
    )}
  </div>
);
class PressAcademy extends React.Component {
  render() {
    if (publishedArticles.length === 0) {
      return null;
    }
    const featuredArticles = publishedArticles.filter((article) => article.featured);
    const slicesBy6 = chunk(featuredArticles, 6);
    const slicesBy3 = chunk(featuredArticles, 3);
    const previousNode = <ChevronLeft width={32} height={32} />;
    const nextNode = <ChevronRight width={32} height={32} />;
    const { linkToPressPage } = this.props;
    return (
      <>
        <Carousel
          indicators={false}
          className="d-none d-lg-block press-academy-carousel"
          prevIcon={previousNode}
          nextIcon={nextNode}
          controls={false}
        >
          {slicesBy6.map((slice, indexX) => (
            <Carousel.Item key={indexX}>
              <Row className="justify-content-center align-items-center press-academy-carousel-row ">
                {slice.map((article, indexY) => (
                  <Col xs={2} className="" key={`${indexX}_${indexY}`}>
                    <AcademyCarouselCard
                      className=""
                      linkToPress={linkToPressPage}
                      {...article}
                    />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
        <Carousel
          indicators={false}
          className="d-flex d-lg-none align-items-center press-carousel"
          prevIcon={previousNode}
          nextIcon={nextNode}
          interval={1500}
          controls={false}
        >
          {slicesBy3.map((slice, indexX) => (
            <Carousel.Item className="" key={`mobile_${indexX}`}>
            {/*  d-flex justify-content-center align-items-center*/}
              <Row className=" press-carousel-row">
                {slice.map((article, indexY) => (
                  <Col
                    xs={4}
                    key={`mobile_${indexX}_${indexY}`}
                    className="pressBanner-col"
                  >
                    <AcademyCarouselCard
                    className="d-flex align-items-center"
                      linkToPress={linkToPressPage}
                      {...article}
                    />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </>
    );
  }
}

export default PressAcademy;
