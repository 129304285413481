import './styles.sass';

import AccountSvg from '../../../images/icons/account.svg';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Flower from '../../../images/mia-flower.svg';
import Image from '../../common/utils/Image';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Section from '../../common/Section';
import StyledLink from '../../common/utils/StyledLink';
import Target from '../../../images/target.svg';
import Testimony from '../../Landing/Testimony';

class Testimonials extends React.Component {
  componentDidMount() {
    if (this.props.from === 'LandingProgram') {
      document.getElementById('testimony-component').classList.add('landing-program');
    }
  }

  showVideo(Videos) {
    if (Videos.id === 1) {
      // jwPlayer
    } else {
      return <img>Videos.thumbnail</img>; // thumbnail
    }
  }

  render() {
    return (

      <Section id="testimony-component" className="background-color-pale-yellow testimony-section ">
        <div className="d-none d-lg-flex flex-column w-100">
          <h2 className="d-flex text-center section-subtitle h2-title justify-content-center">
            Elles témoignent
          </h2>
          <div className="testimony-book-div">
            <div className="first-book landing-program-book">
              <div className="testimony-svg-div">
                <Flower className="testimony-desktop-flower" width={36} height={36} />
              </div>
              <div className="left-page">
                <span>
                  {this.props.data[0].firstPart}
                </span>
              </div>
              <div className="right-page">
                <span>
                  {this.props.data[0].secondPart}
                </span>
                <span className="name-age">
                  {this.props.data[0].patient}, {this.props.data[0].age}
                </span>
              </div>
              <div className="border-div" />
              <div className="border-div" />
              <div className="border-div" />
            </div>
            <div className="second-book landing-program-book">
              <div className="testimony-svg-div">
                <Target className="testimony-desktop-target" width={44} height={56} />
              </div>
              <div className="left-page">
                <span>
                  {this.props.data[1].firstPart}
                </span>
              </div>
              <div className="right-page">
                <span>
                  {this.props.data[1].secondPart}
                </span>
                <span className="name-age">
                  {this.props.data[1].patient}, {this.props.data[1].age}
                </span>
              </div>
              <div className="border-div" />
              <div className="border-div" />
              <div className="border-div" />
            </div>
          </div>
        </div>
        <div className="testimony-div d-lg-none">
          <h2 className="text-center section-subtitle-testimonials">
            Elles témoignent
          </h2>
          <Row className="mb-lg-5 mx-0 d-flex align-items-center justify-content-center">
            <div className="d-flex carousel-testimony">
              {this.props.data.map((testimony) => (
                <Col
                  xs={{ offset: 1, span: 9 }}
                  sm={{ offset: 1, span: 6 }}
                  md={{ offset: 0, span: 5 }}
                  lg={{ offset: 0, span: 4 }}
                  className="mt-4 carousel-testimony-col"
                  key={testimony.city}
                >
                  <Testimony data={testimony} />
                </Col>
              ))}
            </div>
            <Button onClick={() => props.goToAcademy()} className=" btn-orange academy-start-cta academy-cta-margin">
              Commencer
            </Button>
          </Row>
        </div>

      </Section>
    );
  }
}
export default Testimonials;
