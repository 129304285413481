import './styles.sass';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PlayButton from 'images/academy/academy-play-blue.svg';
import PlayButtonWhite from 'images/academy/academy-play-white.svg';
import React from 'react';
import ReactJWPlayer from 'react-jw-player';
import Section from '../../common/Section';
import { StaticImage } from 'gatsby-plugin-image';
import ThumbnailPlayButton from 'images/academy/academy-thumbnail-hero.svg'
import OrangePlayButton from 'images/academy/Play-orange.svg';
import TransparentPlayButton from 'images/academy/academy-transparent-play-button.svg';
import VaginismeVideos from '../VaginismeVideos/data.json';
import playlistVaginisme from '../VaginismeVideos/long-trailer.json';

const goToAnchor = (anchorName) => {
  const url = new URL(window.location.toString());
  url.hash = anchorName;
  window.location.href = url.toString();
};

const goToAnchorBis = (anchorName) => {
  const top = document.getElementById(anchorName).offsetTop; //Getting Y of target element
  window.scrollTo(0, top - 125 );
 
  
};

class LandingHero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideoModal: false,
    };
  }



  handleClose = () => {
    this.setState({ showVideoModal: false });
  }

  handleShow = () => {
    this.setState({ showVideoModal: true });
  }

  render() {
    let heroImage= (
      <StaticImage
        src="../../../images/academy/vaginisme-gilbert-camille.png"
        alt="gilbert is talking"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        className="academy-vaginisme-image"
      />
    );
    let playlist = playlistVaginisme;

    let video;
    return (
      <Section className="hero-section flex-column align-content-center">
        <div className="hero-featured-text d-flex flex-column justify-content-center text-white">
          <div className="catch-phrase">
            <h1 className="lead h1-academy">
              <span className="titre">
                Programme
                {' '}
                {this.props.data.title}
              </span>
              <span className="sous-titre"><i>{this.props.data.text}</i></span>
           </h1>
            <div className="academy-vaginisme-image-wrapper">
              {heroImage}
            </div>
            <div className="under-gilbert-div">
              <div className="video-reader-wrapper">
          <img src={VaginismeVideos[1].image}
               className="thumbnail-hero"
               onClick={this.handleShow}
           />
           <div className="video-reader-shadow">
           <div className="animated-teaser-button">
                <TransparentPlayButton
                 width={22}
                  height={22}
                   stroke="white"
                    fill-opacity={100}
                    className="play-button-thumbnail"
                    onClick={this.handleShow}
                     />
             <span className="thumbnail-teaser-text">Voir la présentation</span>
             </div>
              </div>
              </div>
              <div className="academy-desktop-h1">
                <span>
                  Programme
                  {' '}
                  {this.props.data.title}
                </span>
                <span><i>{this.props.data.text}</i></span>
              </div>
              <div className="d-flex hero-section-cta-block">
                <span>
                  Thérapie complète à partir de
                  {' '}
                  {this.props.data.price}
                  {' '}
                  €
                </span>
                <div className="video-popup-button" onClick={this.handleShow}>
                  <OrangePlayButton width={22}
                   height={22}
                    className="play-button"
                    fill-opacity={100}
                     />
                  Voir la présentation
                </div>
                <Button onClick={() => this.props.goToAcademy()} className="btn-orange border-0">
                  Accéder au programme
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          centered
          show={this.state.showVideoModal}
          onHide={this.handleClose}
          size="xl"

        >
          <ReactJWPlayer
            playerId="program-summary"
            playerScript="https://cdn.jwplayer.com/libraries/7MudfjaW.js"
            playlist={playlist}
            onPlay={this.handleShow}
            isAutoPlay={true}
          />
        </Modal>
      </Section>
    );
  }
}
                // <Button onClick={() => goToAnchorBis('academy-email')} className="btn-white">
                //   Recevoir un épisode gratuit
                // </Button>
export default LandingHero;
