import React from 'react';
import Section from '../../common/Section';
import './styles.sass';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Image from '../../common/utils/Image';

const IndexPage = (props) => (
  <Section className="academy-section academy-efficacity">
    <Container className="academy-efficacity-container">
      <h2>Pourquoi ce programme est efficace&nbsp;?</h2>
      <span>
        {props.data.topPhrase}
      </span>
      <div className="academy-reason-wrapper">
        {props.data.categories.map((item, index) => (
          <div className="academy-reason">
            <Image filename={item.image} alt="" />
            <span className="academy-reason-title">{item.title}</span>
            <span className="academy-reason-text">
              {item.text}
            </span>
          </div>
        ))}
      </div>
      <Button onClick={() => props.goToAcademy()} className="btn-orange academy-start-cta academy-cta-margin">
        Commencer
      </Button>
    </Container>
  </Section>
);

export default IndexPage;
