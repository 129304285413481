import React from 'react';
import './styles.sass';
import LandingProgram from '../LandingProgram';
import data from './data.json';

const IndexPage = () => (
  <LandingProgram data={data} />
);

export default IndexPage;
