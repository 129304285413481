const EMBARGO_DATE = 1558497600000; // Date.parse("2019-05-22T06:00:00+02:00")

export const articles = [
  {
    media: 'RTL',
    published: true,
    featured: true,
    title:
      'Sexe : le tabou des troubles de la sexualité n’épargne pas les hommes',
    description:
      'La majorité des hommes ne consultent pas quand ils ont des problèmes d\'érection selon une enquête de l\'Ifop réalisée pour Charles, une plateforme de santé sexuelle destinée aux hommes.',
    location:
      'https://www.rtl.fr/girls/love/sexe-le-tabou-des-troubles-de-la-sexualite-n-epargne-pas-les-hommes-7797674902',
    brandImage: 'press/RTL_logo.svg',
    alt: 'Charles sur RTL',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'FranceInfo',
    published: true,
    featured: true,
    title: '6 hommes sur 10 ont connu des pannes d\'érection selon une étude, et "c\'est normal"',
    description: '60% des hommes ont déjà rencontré au moins une fois au cours de leur vie des problèmes d\'érection, un chiffre en hausse selon un sondage Ifop. "Il est important de dédramatiser", nuance le psychiatre Philippe Brenot.',
    location:
      'https://www.francetvinfo.fr/sante/sexo/6-hommes-sur-10-ont-connu-des-pannes-d-erection-selon-une-etude-et-c-est-normal_3454529.html',
    brandImage: 'press/france-info.svg',
    alt: 'Charles sur FranceInfo',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'BFM',
    published: true,
    featured: true,
    title:
      'Les Français plus nombreux que jamais à souffrir de troubles de l\'érection',
    description: 'En France, 61% des hommes ont déjà été victimes de troubles de l\'érection, d\'après une nouvelle étude de l\'Ifop publiée ce mercredi. Un sujet encore majoritairement "tabou" chez la gente masculine, souligne l\'étude.',
    location:
      'https://www.bfmtv.com/sante/les-francais-plus-nombreux-que-jamais-a-souffrir-de-troubles-de-l-erection-1696295.html',
    brandImage: 'press/BFM_TV.svg',
    alt: 'Charles sur BFM',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Europe 1',
    published: true,
    featured: true,
    title:
      'Charles.co, une plateforme en ligne pour aider les hommes qui souffrent de problèmes d\'érection',
    description:
      'Six hommes sur dix ont eu au cours de leur vie des troubles érectiles, selon une étude de l\'Ifop. Pour décomplexer, comprendre et soigner ce trouble souvent considéré comme honteux, Charles.co convoque sexologues, pharmaciens et médecins.',
    location:
      'hhttps://www.europe1.fr/emissions/les-trophees-de-lavenir/charlesco-une-plateforme-en-ligne-pour-aider-les-hommes-qui-souffrent-de-problemes-derection-3900119',
    brandImage: 'press/Europe1.svg',
    alt: 'Charles sur Europe1',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Les Echos',
    published: true,
    featured: true,
    title: 'Charles veut digitaliser la santé masculine',
    description:
      'La start-up lance une plate-forme de télémédecine dédiée aux troubles de l\'érection. Elle veut devenir référente en matière de santé des hommes.',
    location:
      'https://business.lesechos.fr/entrepreneurs/actu/0601267698207-charles-veut-digitaliser-la-sante-masculine-329412.php',
    brandImage: 'press/Les_echos.svg',
    alt: 'Charles sur les Echos',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'GQ Magazine',
    published: true,
    featured: true,
    title:
      'La dépendance aux écrans pourrait engendrer la dysfonction érectile',
    description: 'La dépendance aux écrans pourrait engendrer la dysfonction érectile.',
    location:
      'https://www.gqmagazine.fr/sexe/article/la-dependance-aux-ecrans-pourrait-engendrer-la-dysfonction-erectile',
    brandImage: 'press/gq.svg',
    alt: 'Charles sur GQ',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'LCI',
    published: true,
    featured: true,
    location:
      'https://www.lci.fr/vie-de-couple/video-jamais-les-francais-n-ont-ete-aussi-nombreux-a-souffrir-de-problemes-d-erection-selon-une-etude-ifop-2121738.html',
    title:
      'Jamais les Français n\'ont été aussi nombreux à souffrir de problèmes d’érection',
    description:
      'EN PANNE - Plus de six Français sur dix ont connu des troubles érectiles au moins une fois au cours de leur vie. C\'est ce qui ressort d\'une enquête Ifop, qui lie en partie cette hausse de la dysfonction érectile à l’hyperconnexion aux écrans.',
    brandImage: 'press/lci.png',
    alt: 'Charles sur LCI',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Huffington Post',
    published: true,
    featured: true,
    location:
      'https://www.huffingtonpost.fr/entry/troubles-de-lerection-les-ecrans-ne-sont-pas-vos-amis_fr_5ce3c4d6e4b0e69c18f12eda',
    title: 'Troubles de l\'érection, les écrans ne sont pas vos amis',
    description:
      'Outre l\'âge et le stress, la dépendance aux écrans a aussi un impact négatif sur la libido.',
    brandImage: 'press/HuffPost.svg',
    alt: 'Charles sur le Huffington Post',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'CNEWS',
    published: true,
    featured: true,
    location:
      'https://www.cnews.fr/france/2019-05-22/la-dependance-aux-ecrans-favorise-les-troubles-de-lerection-selon-une-etude-ifop',
    title:
      'La dépendance aux écrans favorise les troubles de l\'érection, selon une étude IFOP',
    description: 'Une étude de l’Ifop, pour la plateforme de santé Charles.co, établit une corrélation entre troubles érectiles et utilisation répétée des écrans d’ordinateurs et de smartphones.',
    brandImage: 'press/cnews.png',
    alt: 'Charles sur CNews',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'RMC',
    published: true,
    location:
      'https://rmc.bfmtv.com/mediaplayer/video/fatigue-netflix-reseaux-sociaux-de-plus-en-plus-de-francais-atteints-de-troubles-de-l-erection-1162774.html',
    title:
      'Fatigue, Netflix, réseaux sociaux: de plus en plus de Français atteints de troubles de l’érection. La dépendance aux écrans favorise les troubles de l\'érection, selon une étude IFOP',
    description: '6 hommes sur 10 ont déjà fait le coup de la panne. Et dans la moitié des cas, c’est à cause du stress.',
    brandImage: 'press/RMC.jpg',
    alt: 'Charles sur RMC',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Lebonbon',
    published: false,
    title:
      'Une étude révèle que les troubles de l\'érection sont en constante augmentation',
    description: 'Sûrement une des situations les plus redoutées chez la gent masculine : les problèmes d\'érection. Et même si les hommes sont de plus en plus nombreux à être concernés au moins une fois dans leur vie par des troubles de l\'érection, la situation reste extrêmement gênante pour beaucoup d\'entre eux et n\'est pas souvent bien vécue. Un manque de confiance en soi, une remise en cause de sa virilité, une humiliation etc... tout y passe. De fait, les problèmes d\'érection sont encore un sujet tabou, souvent difficile à aborder avec son partenaire ou son médecin.',
    location:
      'https://www.lebonbon.fr/paris/news/etude-troubles-erection-constante-augmentation/',
    brandImage: 'press/LeBonbon.svg',
    alt: 'Charles sur Lebonbon',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Medisite.fr',
    published: true,
    featured: true,
    location:
      'https://www.medisite.fr/troubles-erectiles-troubles-erectiles-en-hausse-les-ecrans-responsables.5511464.23689.html',
    title: 'Troubles érectiles en hausse : les écrans responsables',
    description:
      'Jamais les Français n’ont été aussi nombreux à souffrir de problèmes d’érection, d’après une nouvelle étude. Les coupables ? YouPorn, Netflix, ou encore Facebook. Les écrans s’avèrent la première cause de la baisse de libido.',
    brandImage: 'press/Medisite.png',
    alt: 'Charles sur Medisite',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'AFP',
    published: false,
    featured: true,
    title: 'l\'AFP relaie l\'étude sur les problèmes d\'érection',
    location: 'https://lci.fr/',
    brandImage: 'press/AFP.svg',
    alt: 'Charles sur l\'AFP',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Elle',
    published: true,
    featured: true,
    title: 'Les hommes aussi ont la migraine et ça peut cacher autre chose',
    description:
      'Souffrir d’un problème d’érection est une chose, en parler en est une autre. A l’heure où les troubles érectiles sont en force hausse en France, le site charles.co ouvre ses portes et propose des consultations en ligne pour soutenir les hommes.',
    location:
      'http://www.elle.fr/Love-Sexe/News/Les-hommes-aussi-ont-la-migraine-et-ca-peut-cacher-autre-chose-3791174',
    brandImage: 'press/ELLE-Magazine-Logo.svg',
    alt: 'Charles sur Elle',
    publicationDate: EMBARGO_DATE,
  },
  {
    media: 'Allo Docteurs',
    published: true,
    featured: true,
    title: 'Ejaculation prématurée : quelle fréquence ? Quels traitements ?',
    location:
      'https://www.allodocteurs.fr/blogs/100-psycho-sexo/ejaculation-prematuree-quelle-frequence-quels-traitements_1347.html',
    brandImage: 'press/allodocteurs_logo.png',
    alt: 'Charles sur Allo Docteurs',
    publicationDate: Date.parse('2019-10-25T06:00:00+02:00'),
  },
  {
    media: 'Yahoo',
    published: true,
    title: 'Troubles de l\'érection : des téléconsultations pour les jeunes',
    location:
      'https://fr.news.yahoo.com/troubles-l%C3%A9rection-t%C3%A9l%C3%A9consultations-jeunes-165814864.html?guccounter=1&guce_referrer=aHR0cHM6Ly9hcHAubWVsdHdhdGVyLmNvbS9zZWFyY2gvbmV3&guce_referrer_sig=AQAAADcg6W7rLzVE93Da4msjUmYni1EO6KwuoJbfEH9U3wTxn1_0R_laL5X1d2GxxHoeRRbbRoy4HAG5tqaQwc24EQBfSb00S0RIuxaXMTqS5P4ADKB4VPNpiToI7gQAw_0_U2ovgUEcpIVKjF9QMVlsKJ_axNUgThTmkudZamMVTgWS',
    brandImage: 'press/Yahoo.png',
    alt: 'Charles sur Yahoo',
    publicationDate: Date.parse('2019-10-24T06:00:00+02:00'),
  },
  {
    media: 'Franceinfo',
    published: true,
    featured: true,
    title: 'Troubles de l\'érection : des téléconsultations pour les jeunes',
    location:
      'https://www.francetvinfo.fr/sante/sexo/troubles-de-l-erection-des-teleconsultations-pour-les-jeunes_3673761.html',
    brandImage: 'press/france-info.svg',
    alt: 'Charles sur Franceinfo',
    publicationDate: Date.parse('2019-10-24T06:00:00+02:00'),
  },
  {
    media: 'Le Magazine de la santé',
    published: true,
    featured: true,
    title: 'Charles.co dans le Magazine de la santé - 24 Octobre 2019',
    location:
      'https://www.dropbox.com/s/7nr78s04m6b49wg/le%20magazine%20de%20la%20sant%C3%A9%20-%20p%C3%A9diatrie%20l%27%C3%A9preuve%20de%20la%20chirurgie_france%205_2019_10_24_13_40.ts?dl=0',
    brandImage: 'press/LEMAGDELASANTE.png',
    alt: 'Charles sur Le Magazine de la santé',
    publicationDate: Date.parse('2019-10-24T06:00:00+02:00'),
  },
  {
    media: 'TopSanté',
    published: true,
    title: 'Éjaculation précoce : elle concerne (presque) tous les hommes',
    location:
      'https://www.topsante.com/couple-et-sexualite/sexualite/troubles-sexuels-chez-l-homme/ejaculation-precoce-elle-concerne-presque-tous-les-hommes-633490',
    brandImage: 'press/topSante.png',
    alt: 'Charles sur TopSanté',
    publicationDate: Date.parse('2019-10-13T06:00:00+02:00'),
  },
  {
    media: 'Le Monde',
    published: true,
    featured: true,
    title: 'Les hommes au défi du rapport sexuel idéal',
    location:
      'https://www.lemonde.fr/m-le-mag/article/2019/10/13/les-hommes-au-defi-du-rapport-sexuel-ideal_6015298_4500055.html',
    brandImage: 'press/leMonde.svg',
    alt: 'Charles sur Le Monde',
    publicationDate: Date.parse('2019-10-13T06:00:00+02:00'),
  },
  {
    media: 'France Inter',
    published: true,
    featured: true,
    title: 'Sexe : les hommes sont programmés pour durer 1 minute 20',
    location:
      'https://www.franceinter.fr/emissions/tanguy-pastureau-maltraite-l-info/tanguy-pastureau-maltraite-l-info-11-octobre-2019',
    brandImage: 'press/France_inter.png',
    alt: 'Charles sur France Inter',
    publicationDate: Date.parse('2019-10-11T06:00:00+02:00'),
  },
  {
    media: 'Santé Magazine',
    published: true,
    featured: true,
    title:
      'L\'éjaculation précoce, une source de préoccupation toujours d\'actualité',
    location:
      'https://www.santemagazine.fr/actualites/actualites-sante/lejaculation-precoce-une-source-de-preoccupation-toujours-dactualite-427509',
    brandImage: 'press/santemagazine.svg',
    alt: 'Charles sur Santé Magazine',
    publicationDate: Date.parse('2019-10-11T06:00:00+02:00'),
  },
  {
    media: 'Femme Actuelle',
    published: true,
    title:
      'Éjaculation précoce : 1 femme sur 3 déclare avoir déjà rompu pour cette raison',
    location:
      'https://www.femmeactuelle.fr/amour/news-amour/ejaculation-precoce-1-femme-sur-3-declare-avoir-deja-rompu-pour-cette-raison-2084865',
    brandImage: 'press/Femme_actuelle.png',
    alt: 'Charles sur Femme Actuelle',
    publicationDate: Date.parse('2019-10-10T06:00:00+02:00'),
  },
  {
    media: 'Pourquoi docteur',
    published: true,
    title:
      'Éjaculation précoce : près d\'un tiers des hommes se sentent concernés',
    location:
      'https://www.pourquoidocteur.fr/Articles/Question-d-actu/30395-Ejaculation-precoce-d-un-tiers-hommes-sentent-concernes',
    brandImage: 'press/Pourquoi_docteur.png',
    alt: 'Charles sur Pourquoi docteur',
    publicationDate: Date.parse('2019-10-09T06:00:00+02:00'),
  },
  {
    media: 'Doctissimo',
    published: true,
    featured: true,
    title:
      '71% des Français estiment avoir des problèmes de rapidité éjaculatoire',
    location:
      'https://www.doctissimo.fr/sexualite/news/enquete-ejaculation-precoce-france',
    brandImage: 'press/doctissimo.png',
    alt: 'Charles sur Doctissimo',
    publicationDate: Date.parse('2019-10-09T06:00:00+02:00'),
  },
  {
    media: 'CNews la matinale',
    published: true,
    title: 'L\'éjaculation précoce touche 71% des hommes selon un sondage Ifop',
    location:
      'https://www.cnews.fr/emission/2019-10-09/la-chronique-sante-du-09102019-887129',
    brandImage: 'press/cnews.png',
    alt: 'Charles sur CNews la matinale',
    publicationDate: Date.parse('2019-10-09T06:00:00+02:00'),
  },
  {
    media: 'BFM TV',
    published: true,
    title: 'L\'éjaculation précoce, un trouble très répandu mais toujours tabou',
    location:
      'https://www.bfmtv.com/sante/l-ejaculation-precoce-un-trouble-tres-repandu-mais-toujours-tabou-1782952.html',
    brandImage: 'press/BFM_TV.svg',
    alt: 'Charles sur BFM TV',
    publicationDate: Date.parse('2019-10-09T06:00:00+02:00'),
  },
  {
    media: 'Médisite',
    published: true,
    title:
      'Ejaculation précoce : 1/3 des femmes quittent leur mari pour cette raison',
    location:
      'https://www.medisite.fr/troubles-erectiles-ejaculation-precoce-un-tiers-des-femmes-quittent-leur-mari-pour-cette-raison.5538220.23689.html',
    brandImage: 'press/Medisite.png',
    alt: 'Charles sur Médisite',
    publicationDate: Date.parse('2019-10-08T06:00:00+02:00'),
  },
  {
    media: 'GQ',
    published: true,
    title:
      'Ejaculation rapide : jouir trop vite, c\'est (statistiquement) normal !',
    location:
      'https://www.gqmagazine.fr/sexe/article/ejaculation-rapide-jouir-trop-vite-cest-statistiquement-normal',
    brandImage: 'press/gq.svg',
    alt: 'Charles sur GQ',
    publicationDate: Date.parse('2019-10-08T06:00:00+02:00'),
  },
  {
    media: 'Psychologies',
    published: true,
    title:
      'L\'éjaculation précoce, une cause de rupture sous-estimée par les hommes ?',
    location:
      'https://www.psychologies.com/Couple/Problemes-sexuels/Ejaculation-precoce/Articles-et-Dossiers/L-ejaculation-precoce-une-cause-de-rupture-sous-estimee-par-les-hommes',
    brandImage: 'press/psychologies.svg',
    alt: 'Charles sur Psychologies',
    publicationDate: Date.parse('2019-10-08T06:00:00+02:00'),
  },
  {
    media: 'France Culture',
    published: true,
    title: 'Un tiers des hommes ont déjà connu un fiasco',
    location:
      'https://www.franceculture.fr/emissions/lhumeur-du-matin-par-guillaume-erner/lhumeur-du-jour-emission-du-mardi-08-octobre-2019',
    brandImage: 'press/France_Culture.png',
    alt: 'Charles sur France Culture',
    publicationDate: Date.parse('2019-10-08T06:00:00+02:00'),
  },
  {
    media: 'BFM TV',
    published: true,
    title:
      'L\'éjaculation précoce a déjà été à l\'origine d\'une rupture pour 29% des femmes, mais seulement 15% des hommes, selon un sondage',
    location:
      'https://www.bfmtv.com/sante/l-ejaculation-precoce-a-deja-ete-a-l-origine-d-une-rupture-pour-29percent-des-femmes-mais-seulement-15percent-des-hommes-selon-un-sondage-1782606.html',
    brandImage: 'press/BFM_TV.svg',
    alt: 'Charles sur Le BFM TV',
    publicationDate: Date.parse('2019-10-08T06:00:00+02:00'),
  },
  {
    media: 'Les Echos WE',
    published: true,
    title: 'Le grand tabou du petit coup de mou',
    location:
      'https://weekend.lesechos.fr/perso/developpement-personnel/0601391464510-sexologie-en-toute-discretion-2273388.php',
    brandImage: 'press/Les_echos.png',
    alt: 'Charles sur Le Les Echos',
    publicationDate: Date.parse('2019-06-14T06:00:00+02:00'),
  },
  {
    media: 'Le Parisien',
    published: true,
    title: 'Sexologie en toute discretion',
    location:
      'https://www.dropbox.com/s/ydbn3mf8wfllgtx/Le_Parisien_270519.pdf',
    brandImage: 'press/LeParisien.png',
    alt: 'Charles sur Le Parisien',
    publicationDate: Date.parse('2019-05-27T06:00:00+02:00'),
  },
  {
    media: 'Le progrès',
    published: true,
    title: 'Six Français sur dix ont déjà eu des problèmes d\'érection',
    location:
      'https://www.leprogres.fr/france-monde/2019/05/27/six-francais-sur-dix-ont-deja-eu-des-problemes-d-erection',
    brandImage: 'press/le-progres.svg',
    alt: 'Charles sur Le Progrès',
    publicationDate: Date.parse('2019-05-27T06:00:00+02:00'),
  },
];

export const publishedArticles = articles.filter((article) => article.published);
