import './styles.sass';

import Container from 'react-bootstrap/Container';
import React from 'react';
import Section from '../../common/Section';
import VaginismeVideos from '../VaginismeVideos/data.json';

class FormationDetails extends React.Component {
  render() {
    let videos = VaginismeVideos;
    
    const detailledVideos = videos.filter((video) => video.showInDetails);
    return (
      <Section className="academy-section academy-section-details" id="details">
        <Container className="px-0 academy-details-container">
          <h2>
            Le programme en détails
          </h2>
          <div className="academy-details-carousel">
            {detailledVideos.map((video, i) => (
              <div key={i} className="academy-section-video-details">
                <div className="">
                  <div className="chapter-image">
                    <img src={video.image} className="img-fluid" alt={video.title} />
                  </div>
                  <div className="video-detail-content">
                    <h5 className="video-title">
                      {video.detailTitle}
                    </h5>
                    <div className="video-description">
                      {video.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Section>
    );
  }
}

export default FormationDetails;
