import './styles.sass';

import { Container } from 'react-bootstrap';
import Image from '../../common/utils/Image';
import React from 'react';
import Section from '../../common/Section';

export default function WhyUs(props) {
  return (
    <Section className="academy-section why-us">
      <Container className="why-us-container">
        {props.data.categories.map((item) => (
          <div>
          <Image filename={item.image} className="why-us-image" alt="why-us" />
          <span className="font-weight-normal">
            {item.text}
          </span>
        </div>
        ))}
      </Container>
    </Section>
  );
}
