import './styles.sass';

import { AcademyLink } from 'components/Start/AcademyLink';
import Container from 'react-bootstrap/Container';
import React from 'react';
import Section from '../../common/Section';
import { StaticImage } from 'gatsby-plugin-image';

class CoachDetails extends React.Component {
  render() {
    return (
      <Section
        className="academy-section academy-section-coach"
        id="coach"
        style={{ backgroundColor: this.props.data.backgroundColor }}
      >
        <Container className="academy-coach-container">
          <h2>
            L'équipe du programme <span className="upper-case">vaginisme</span>
          </h2>
          <div className="coach-details-content">
            <div className="featured-doctor">
              <StaticImage
                src="../../../images/academy/coach-vaginisme.png"
                alt="gilbert is talking"
                placeholder="blurred"
                layout="constrained"
                quality={100}
                className="featured-image"
              />
              <p className="description">
                {this.props.data.text}
              </p>
            </div>
            <div className="profile">
              {this.props.data.details.map((detail, index) => (
                <div className="doctor-informations">
                  <div className="doctor-name" key={index}>
                    {detail.name}
                  </div>
                  <div className="doctor-text" key={index}>
                    {detail.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Section>
    );
  }
}
export default CoachDetails;
